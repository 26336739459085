<template>
  <div class="custom-line-item-template">
    <table width="100%" class="line-item-multiple-table">
      <thead>
        <tr>
          <th
            height="40px"
            width="55%"
            class="orange--text text--darken-4 pl-4 font-size-16"
          >
            {{ itemTitle }} ({{ line_items.length }})
          </th>
          <th
            height="40px"
            width="15%"
            class="orange--text text--darken-4 font-size-16"
            align="right"
          >
            Quantity
          </th>
          <th
            height="40px"
            width="15%"
            class="orange--text text--darken-4 font-size-16"
            align="right"
          >
            Rate
          </th>
          <th
            height="40px"
            width="15%"
            class="orange--text text--darken-4 font-size-16 pr-4"
            align="right"
          >
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="loading">
          <tr>
            <td colspan="4">
              <div class="my-10 mx-5 d-flex align-center justify-center">
                <v-progress-circular
                  :width="3"
                  color="cyan"
                  indeterminate
                ></v-progress-circular>
                <p class="font-size-16 mx-5 my-0 font-weight-600">Loading...</p>
              </div>
            </td>
          </tr>
        </template>
        <template v-else-if="line_items.length">
          <template v-for="(row, index) in line_items">
            <tr
              :key="`line-item-${index}`"
              :class="{
                'line-item-one-row': index % 2 == 0,
                'line-item-two-row': !(index % 2 == 0),
              }"
            >
              <td valign="top" width="55%" class="pl-4">
                <br />
                <table width="100%" style="table-layout: fixed">
                  <tr>
                    <td width="100px" valign="top" align="center">
                      <label
                        class="py-0 my-0 pt-2 font-weight-600 font-size-18"
                      >
                        VO {{ index + 1 }}.
                      </label>
                    </td>
                    <td valign="top">
                      <div class="px-2 custom-grey-border">
                        <v-layout class="my-1">
                          <v-flex>
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                  label
                                  class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                  text-color="white"
                                  color="cyan"
                                >
                                  {{ get_product_type(row.product_type) }}
                                </v-chip>
                              </template>
                              <span class="text-capitalize">Type</span>
                            </v-tooltip>
                          </v-flex>
                          <v-flex class="mx-2">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                  label
                                  class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                  text-color="white"
                                  color="cyan"
                                >
                                  {{ row.barcode }}
                                </v-chip>
                              </template>
                              <span class="text-capitalize"
                                >{{
                                  get_product_type(row.product_type)
                                }}
                                #</span
                              >
                            </v-tooltip>
                          </v-flex>
                          <v-flex md10 class="font-size-16 font-weight-500">{{
                            row.product
                          }}</v-flex>
                        </v-layout>
                        <div
                          v-if="row.description || row.images.length"
                          class="font-size-16 py-1 font-weight-400 custom-border-top"
                          style="white-space: pre-line"
                        >
                          <div v-html="row.description" v-if="row.description" class="custom-border-bottom my-2"></div>
                          <div v-if="row.images.length" class="d-flex" >
                            <div style="position:relative" v-for="(nimg, nmlindex) in row.images" :key="`new-image-${nmlindex}`">
                              <v-img :aspect-ratio="1" height="100px" width="100px" class="mr-4 my-2" :src="nimg">              
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="grey darken-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>
                          </div>
                        </div>
                        <template v-if="row.new_item">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="custom-status mb-2 font-weight-600 custom-grey-border text-uppercase new-add-on-chip"
                              >
                                New ADD-ON
                              </v-chip>
                            </template>
                            <span class="text-capitalize">New ADD-ON</span>
                          </v-tooltip>
                        </template>
                        <template v-if="row.strike">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="custom-status mb-2 font-weight-600 custom-grey-border text-uppercase"
                                text-color="white"
                                color="red"
                              >
                                Removed
                              </v-chip>
                            </template>
                            <span class="text-capitalize">Removed</span>
                          </v-tooltip>
                        </template>
                        <template v-if="row.is_optional">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="custom-status mr-2 font-weight-600 mb-2 custom-grey-border text-uppercase"
                                text-color="white"
                                color="orange"
                              >
                                Optional
                              </v-chip>
                            </template>
                            <span class="text-capitalize"
                              >Marked as Optional</span
                            >
                          </v-tooltip>
                        </template>
                      </div>
                    </td>
                  </tr>
                </table>
                <br />
              </td>
              <td valign="top" width="15%" align="right"></td>
              <td valign="top" width="15%" align="right"></td>
              <td valign="top" width="15%" align="right" class="pr-4"></td>
            </tr>
            <template v-if="row.child_items">
              <template v-for="(crow, cindex) in row.child_items">
                <tr
                  v-if="crow.show_label"
                  class="custom-border-top"
                  :key="`child-line-item-label-${index}-${cindex}`"
                  :class="{
                    'line-item-one-row': index % 2 == 0,
                    'line-item-two-row': !(index % 2 == 0),
                  }"
                >
                  <td valign="top" width="54%" class="pl-4">
                    <table width="100%" style="table-layout: fixed">
                      <tr>
                        <td width="110px" align="center" valign="top"></td>
                        <td align="center">
                          <label
                            class="mb-3 mt-3 py-0 pl-0 pr-4 font-weight-600 font-size-18"
                          >
                            {{ crow.label }}
                          </label>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td valign="top" width="10%" align="right"></td>
                  <td valign="top" width="18%" align="right"></td>
                  <td valign="top" width="18%" align="right" class="pr-4"></td>
                </tr>

                <tr
                  :key="`child-line-item-${index}-${cindex}`"
                  :class="{
                    'line-item-one-row': index % 2 == 0,
                    'line-item-two-row': !(index % 2 == 0),
                  }"
                >
                  <td rowspan="3" valign="top" width="55%" class="pl-4">
                    <table width="100%" style="table-layout: fixed">
                      <tr>
                        <td width="100px" valign="top" align="center">
                          <label
                            class="mb-0 mt-3 py-0 pl-0 pr-4 font-weight-600 font-size-18"
                          >
                            {{ index + 1 }}.{{ cindex + 1 }}
                          </label>
                        </td>
                        <td class="px-2 custom-grey-border">
                          <p class="font-size-16 py-1 font-weight-400 my-0">
                            {{ crow.product_name }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td width="100px" valign="top" align="center"></td>
                        <td class="px-2 custom-grey-border">
                          <p
                            class="font-size-16 py-1 my-0 font-weight-400"
                            style="white-space: pre-line"
                          >
                            <span v-html="crow.description"></span>
                          </p>
                        </td>
                      </tr>
                    </table>
                    <div class="w-100 text-right" v-if="crow.new_item">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            label
                            class="custom-status mr-2 mt-2 font-weight-600 custom-grey-border text-uppercase new-add-on-chip"
                          >
                            New ADD-ON
                          </v-chip>
                        </template>
                        <span class="text-capitalize">New ADD-ON</span>
                      </v-tooltip>
                    </div>
                    <div class="w-100 text-right" v-if="crow.strike">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            label
                            class="custom-status mr-2 mt-2 font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            color="red lighten-1"
                          >
                            Removed
                          </v-chip>
                        </template>
                        <span class="text-capitalize">Marked as Removed</span>
                      </v-tooltip>
                    </div>
                    <div class="w-100 text-right" v-if="crow.is_optional">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            label
                            class="custom-status mr-2 mt-2 font-weight-600 custom-grey-border text-uppercase"
                            text-color="white"
                            color="orange"
                          >
                            Optional
                          </v-chip>
                        </template>
                        <span class="text-capitalize">Marked as Optional</span>
                      </v-tooltip>
                    </div>
                  </td>
                  <td height="39px" valign="top" width="15%" align="right">
                    <p
                      class="font-size-16 px-2 font-weight-600 text-left custom-grey-border py-1 mb-0"
                      style="min-height: 39px"
                    >
                      <span class="d-flex justify-content-between">
                        <span>{{ formatNumber(crow.quantity) }}</span>
                        <span class="text-capitalize">{{ crow.uom }}</span>
                      </span>
                    </p>
                  </td>
                  <td height="39px" valign="top" width="15%" align="right">
                    <p
                      class="font-size-16 px-2 font-weight-600 text-left custom-grey-border py-1 mb-0"
                      style="min-height: 39px"
                    >
                      {{ formatMoneyTwoDecimal(crow.rate) }}
                    </p>
                  </td>
                  <td
                    height="39px"
                    valign="top"
                    width="15%"
                    align="right"
                    class="pr-4"
                  >
                    <p
                      class="font-size-16 px-2 font-weight-600 text-left custom-grey-border py-1 mb-0"
                      style="min-height: 39px"
                    >
                      {{ formatMoneyTwoDecimal(crow.total) }}
                    </p>
                  </td>
                </tr>

                <tr
                  :key="`child-line-item-extra-last-${index}-${cindex}`"
                  :class="{
                    'line-item-one-row': index % 2 == 0,
                    'line-item-two-row': !(index % 2 == 0),
                  }"
                >
                  <td valign="top" width="15%" align="right"></td>
                  <td valign="top" width="15%" align="right">
                    <p
                      class="w-100 my-1 text-left font-size-14 font-weight-400"
                    >
                      Supplier Cost:&nbsp;&nbsp;<label
                        class="m-0 p-0 font-weight-500"
                        >{{ formatMoneyTwoDecimal(crow.company_cost) }}</label
                      >
                    </p>
                    <p
                      class="w-100 my-1 text-left font-size-14 font-weight-400"
                    >
                      Profit:
                      <label class="m-0 p-0 font-weight-500">{{
                        formatMoneyTwoDecimal(crow.profit)
                      }}</label>
                    </p>
                    <p
                      class="w-100 mt-1 text-left font-size-14 font-weight-400"
                    >
                      Markup (in %):
                      <label class="m-0 p-0 font-weight-500"
                        >{{ formatNumber(crow.markup_percent) }}%</label
                      >
                    </p>
                  </td>
                  <td valign="top" width="15%" align="right" class="pr-4">
                    <p
                      class="w-100 my-1 text-left font-size-14 font-weight-400"
                    >
                      Total Supplier Cost:&nbsp;&nbsp;<label
                        class="m-0 p-0 font-weight-500"
                        >{{ formatMoneyTwoDecimal(crow.total_company_cost) }}</label
                      >
                    </p>
                    <p
                      class="w-100 my-3 text-left font-size-14 font-weight-400"
                    >
                      Profit (in %):
                      <label class="m-0 p-0 font-weight-500"
                        >{{
                          formatNumber(crow.profit_percent)
                        }}%</label
                      >
                    </p>
                  </td>
                </tr>

                <tr
                  :key="`child-line-item-extra-${index}-${cindex}`"
                  :class="{
                    'line-item-one-row': index % 2 == 0,
                    'line-item-two-row': !(index % 2 == 0),
                  }"
                >
                  <td
                    valign="top"
                    colspan="2"
                    width="55%"
                    class="pl-4 custom-grey-border"
                  >
                    <p
                      class="font-size-16 py-1 my-0 font-weight-400"
                      style="white-space: pre-line"
                    >
                      <span v-html="crow.internal_note"></span>
                    </p>
                  </td>
                  <td valign="top" width="15%" align="right"></td>
                </tr>

                <template v-if="crow.items">
                  <template v-for="(clrow, clindex) in crow.items">
                    <tr
                      :key="`child-line-item-${index}-${cindex}-${clindex}`"
                      :class="{
                        'line-item-one-row': index % 2 == 0,
                        'line-item-two-row': !(index % 2 == 0),
                      }"
                    >
                      <td rowspan="2" valign="top" width="55%" class="pl-4">
                        <br />
                        <table width="100%" style="table-layout: fixed">
                          <tr>
                            <td width="50px" valign="top" align="center"></td>
                            <td width="100px" valign="top" align="center">
                              <v-icon style="margin-top: 50px"
                                >mdi-chevron-right</v-icon
                              >
                            </td>
                            <td class="px-2 custom-grey-border">
                              <p
                                class="font-size-16 py-1 font-weight-400 custom-border-bottom"
                                style="white-space: pre-line"
                              >
                                <span v-html="clrow.title"></span>
                              </p>
                              <p
                                class="font-size-16 py-1 font-weight-400"
                                style="white-space: pre-line"
                              >
                                <span v-html="clrow.description"></span>
                              </p>
                            </td>
                          </tr>
                        </table>
                        <div class="w-100 text-right" v-if="clrow.new_item">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="custom-status mr-2 mt-2 font-weight-600 custom-grey-border text-uppercase new-add-on-chip"
                              >
                                New ADD-ON
                              </v-chip>
                            </template>
                            <span class="text-capitalize">New ADD-ON</span>
                          </v-tooltip>
                        </div>
                        <div class="w-100 text-right" v-if="clrow.strike">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="custom-status mr-2 mt-2 font-weight-600 custom-grey-border text-uppercase"
                                text-color="white"
                                color="red lighten-1"
                              >
                                Removed
                              </v-chip>
                            </template>
                            <span class="text-capitalize"
                              >Marked as Removed</span
                            >
                          </v-tooltip>
                        </div>
                        <div class="w-100 text-right" v-if="clrow.is_optional">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                class="custom-status mr-2 mt-2 font-weight-600 custom-grey-border text-uppercase"
                                text-color="white"
                                color="orange"
                              >
                                Optional
                              </v-chip>
                            </template>
                            <span class="text-capitalize"
                              >Marked as Optional</span
                            >
                          </v-tooltip>
                        </div>
                        <br />
                      </td>
                      <td valign="top" width="15%" align="right">
                        <br />
                        <p
                          class="font-size-16 px-2 font-weight-600 text-left custom-grey-border py-1"
                        >
                          <span class="d-flex justify-content-between">
                            <span>{{ formatNumber(clrow.quantity) }}</span>
                            <span class="text-capitalize">{{ clrow.uom }}</span>
                          </span>
                        </p>
                      </td>
                      <td valign="top" width="15%" align="right">
                        <br />
                        <p
                          class="font-size-16 px-2 font-weight-600 text-left custom-grey-border py-1"
                        >
                          {{ formatMoneyTwoDecimal(clrow.rate) }}
                        </p>
                        <p
                          class="w-100 my-1 text-left font-size-14 font-weight-400"
                        >
                          Supplier Cost:&nbsp;&nbsp;<label
                            class="m-0 p-0 font-weight-500"
                            >{{
                              formatMoneyTwoDecimal(clrow.company_cost)
                            }}</label
                          >
                        </p>
                        <p
                          class="w-100 my-1 text-left font-size-14 font-weight-400"
                        >
                          Profit:
                          <label class="m-0 p-0 font-weight-500">{{
                            formatMoneyTwoDecimal(clrow.profit)
                          }}</label>
                        </p>
                        <p
                          class="w-100 mt-1 text-left font-size-14 font-weight-400"
                        >
                          Markup (in %):
                          <label class="m-0 p-0 font-weight-500"
                            >{{
                              formatMoneyTwoDecimal(clrow.markup_percent)
                            }}%</label
                          >
                        </p>
                      </td>
                      <td valign="top" width="15%" align="right" class="pr-4">
                        <br />
                        <p
                          class="font-size-16 px-2 font-weight-600 text-left custom-grey-border py-1"
                        >
                          {{ formatMoneyTwoDecimal(clrow.total) }}
                        </p>
                        <p
                          class="w-100 my-1 text-left font-size-14 font-weight-400"
                        >
                          Total Supplier Cost:&nbsp;&nbsp;<label
                            class="m-0 p-0 font-weight-500"
                            >{{
                              formatMoneyTwoDecimal(clrow.total_company_cost)
                            }}</label
                          >
                        </p>
                        <p
                          class="w-100 my-3 text-left font-size-14 font-weight-400"
                        >
                          Profit (in %):
                          <label class="m-0 p-0 font-weight-500"
                            >{{
                              formatMoneyTwoDecimal(clrow.profit_percent)
                            }}%</label
                          >
                        </p>
                      </td>
                    </tr>

                    <tr
                      :key="`child-line-item-extra-${index}-${cindex}-${clindex}`"
                      :class="{
                        'line-item-one-row': index % 2 == 0,
                        'line-item-two-row': !(index % 2 == 0),
                      }"
                    >
                      <td
                        valign="top"
                        colspan="2"
                        width="55%"
                        class="pl-4 custom-grey-border"
                      >
                        <p
                          class="font-size-16 py-1 my-0 font-weight-400"
                          style="white-space: pre-line"
                        >
                          <span v-html="clrow.internal_note"></span>
                        </p>
                      </td>
                      <td valign="top" width="15%" align="right"></td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
            <tr
              :key="`line-item-pagebreak-${index}`"
              :class="{
                'line-item-one-row': index % 2 == 0,
                'line-item-two-row': !(index % 2 == 0),
              }"
              v-if="row.pagebreak"
            >
              <td colspan="4" class="custom-border-top">
                <div class="text-center my-3 d-flex justify-space-around">
                  <p
                    class="my-0 color-custom-blue font-size-17 font-weight-700"
                  >
                    PAGE BREAK
                  </p>
                  <p
                    class="my-0 color-custom-blue font-size-17 font-weight-700"
                  >
                    PAGE BREAK
                  </p>
                  <p
                    class="my-0 color-custom-blue font-size-17 font-weight-700"
                  >
                    PAGE BREAK
                  </p>
                </div>
              </td>
            </tr>
          </template>
        </template>
        <template v-else>
          <tr>
            <td colspan="4">
              <p
                class="m-0 my-5 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no
                <span class="text-lowercase">{{ itemTitle }}</span>
                at the moment.
              </p>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ObjectPath from "object-path";
import { QUERY } from "@/core/services/store/request.module";
import {
  safeSubtract,
  safeMultiply,
  safeDivide,
  isSafeInteger,
} from "@/core/plugins/math.lib";

export default {
  name: "line-item",
  model: {
    prop: "value",
    event: "input",
  },
  mixins: [CommonMixin],
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(param) {
        if (!this.lodash.isEqual(param, this.line_items)) {
          this.init_line_item(param);
        }
      },
    },
  },
  props: {
    value: {
      type: Array,
      default() {
        return new Array();
      },
    },
    itemTitle: {
      type: String,
      default: "Item",
    },
  },
  data() {
    return {
      line_items: [],
      loading: false,
    };
  },
  methods: {
    get_product_type(type) {
      if (type == "service") {
        return "Additional Work";
      } else if (type == "adtitle") {
        return "Additional Work Title";
      } else {
        return type;
      }
    },
    remove_row(index) {
      ObjectPath.del(this.line_items, index);
      this.$nextTick(() => {
        this.emit_value();
      });
    },
    init_line_item(param) {
      const line_item = this.lodash.cloneDeep(param);
      if (line_item && line_item.length) {
        for (let i = 0; i < line_item.length; i++) {
          this.line_items.push(line_item[i]);
        }
      }
    },

    /* */

    calc_profit_child_one_supplier(index, cindex, clindex) {
      let { company_cost, quantity, rate } =
        this.line_items[index].child_items[cindex].items[clindex];
      if (
        isSafeInteger(company_cost) &&
        isSafeInteger(quantity) &&
        isSafeInteger(rate)
      ) {
        let total_cost = safeMultiply(quantity, rate);
        let supplier_cost = safeMultiply(company_cost, quantity);
        let profit = safeSubtract(total_cost, supplier_cost);
        return this.formatMoney(profit);
      } else {
        return this.formatNumber(0);
      }
    },
    calc_profit_percent_child_one_supplier(index, cindex, clindex) {
      let { company_cost, quantity, rate } =
        this.line_items[index].child_items[cindex].items[clindex];
      if (
        isSafeInteger(company_cost) &&
        isSafeInteger(quantity) &&
        isSafeInteger(rate)
      ) {
        let total_cost = safeMultiply(quantity, rate);
        let supplier_cost = safeMultiply(company_cost, quantity);
        let profit = safeSubtract(total_cost, supplier_cost);
        let profit_percent = safeDivide(profit, total_cost);
        return this.formatNumber(profit_percent);
      } else {
        return this.formatNumber(0);
      }
    },
    calc_markup_child_one_supplier(index, cindex, clindex) {
      let { company_cost, quantity, rate } =
        this.line_items[index].child_items[cindex].items[clindex];
      if (
        isSafeInteger(company_cost) &&
        isSafeInteger(quantity) &&
        isSafeInteger(rate)
      ) {
        if (company_cost > 0 && rate > 0 && quantity > 0) {
          let total_cost = safeMultiply(quantity, rate);
          let supplier_cost = safeMultiply(company_cost, quantity);
          let markup = safeDivide(total_cost, supplier_cost) - 1;
          return this.formatNumber(markup);
        }
        return this.formatNumber(0);
      } else {
        return this.formatNumber(0);
      }
    },
    calc_profit_child_supplier(index, cindex) {
      let { company_cost, quantity, rate } =
        this.line_items[index].child_items[cindex];
      if (
        isSafeInteger(company_cost) &&
        isSafeInteger(quantity) &&
        isSafeInteger(rate)
      ) {
        let total_cost = safeMultiply(quantity, rate);
        let supplier_cost = safeMultiply(company_cost, quantity);
        let profit = safeSubtract(total_cost, supplier_cost);
        return this.formatMoney(profit);
      } else {
        return this.formatNumber(0);
      }
    },
    calc_profit_percent_child_supplier(index, cindex) {
      let { company_cost, quantity, rate } =
        this.line_items[index].child_items[cindex];
      if (
        isSafeInteger(company_cost) &&
        isSafeInteger(quantity) &&
        isSafeInteger(rate)
      ) {
        let total_cost = safeMultiply(quantity, rate);
        let supplier_cost = safeMultiply(company_cost, quantity);
        let profit = safeSubtract(total_cost, supplier_cost);
        let profit_percent = safeDivide(profit, total_cost);
        return this.formatNumber(profit_percent);
      } else {
        return this.formatNumber(0);
      }
    },
    calc_markup_child_supplier(index, cindex) {
      let { company_cost, quantity, rate } =
        this.line_items[index].child_items[cindex];
      if (
        isSafeInteger(company_cost) &&
        isSafeInteger(quantity) &&
        isSafeInteger(rate)
      ) {
        if (company_cost > 0 && rate > 0 && quantity > 0) {
          let total_cost = safeMultiply(quantity, rate);
          let supplier_cost = safeMultiply(company_cost, quantity);
          let markup = safeDivide(total_cost, supplier_cost) - 1;
          return this.formatNumber(markup);
        }
        return this.formatNumber(0);
      } else {
        return this.formatNumber(0);
      }
    },

    /* */
    calc_child_supplier(index, cindex) {
      let { company_cost, quantity } =
        this.line_items[index].child_items[cindex];
      let output = company_cost * quantity;
      return this.formatMoney(output);
    },
    calc_child_one_supplier(index, cindex, clindex) {
      let { company_cost, quantity } =
        this.line_items[index].child_items[cindex].items[clindex];
      let output = company_cost * quantity;
      return this.formatMoney(output);
    },
    get_profit(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const profit = line_item.rate - line_item.company_cost;
      return this.formatMoney(profit);
    },
    get_total_company_cost(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const company_cost = line_item.company_cost * line_item.quantity;
      return this.formatMoney(company_cost);
    },
    get_total_charges(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const charges = line_item.rate * line_item.quantity;
      return this.formatMoney(charges);
    },
    get_net_price(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const charges = line_item.net_price * line_item.quantity;
      return this.formatMoney(charges);
    },
    get_total_profit(index) {
      const line_item = this.lodash.cloneDeep(this.line_items[index]);
      const profit =
        (line_item.rate - line_item.company_cost) * line_item.quantity;
      return this.formatMoney(profit);
    },
    update_value(index) {
      if (this.line_items[index]) {
        const { rate, quantity } = this.line_items[index];
        this.line_items[index].total = rate * quantity;
        this.$nextTick(() => {
          this.emit_value();
        });
      }
    },
    emit_value() {
      this.$emit("input", this.line_items);
      this.$emit("change", this.line_items);
    },
    get_line_items() {
      this.$store
        .dispatch(QUERY, {
          url: "line-item-services",
          data: {
            type: this.itemType,
            search: this.search,
            alphabet: this.alphabet,
            category: this.category,
          },
        })
        .then((response) => {
          this.line_items = ObjectPath.get(response, "data.line_items", []);
          this.categories = ObjectPath.get(response, "data.categories", []);
          this.emit_value();
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
