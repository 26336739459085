<template>
  <DetailTemplate
    :customClass="'quotation-detail detail-page'"
    v-if="getPermission('quotation:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="130"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ detail.quotation_title }}
          </h1>
          <CustomStatus
            :status="detail.status"
            endpoint="quotation/status"
          ></CustomStatus>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="currentUser.super_admin == 1">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button"
              color="red lighten-1 white--text"
              v-bind="attrs"
              v-on="on"
              v-on:click="openDeleteDialog()"
            >
              <v-icon left>mdi-delete</v-icon> Delete
            </v-btn>
          </template>
          <span>Delete Quotation</span>
        </v-tooltip>
      </template>
      <v-tooltip top content-class="custom-top-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan darken-4"
            v-bind="attrs"
            v-on="on"
            v-on:click="updateMoreAction('duplicate')"
          >
            <v-icon left>mdi-content-copy</v-icon> Duplicate
          </v-btn>
        </template>
        <span>Duplicate Quotation</span>
      </v-tooltip>
      <template v-if="prospect.date_converted && detail.is_accepted">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
              v-on:click="updateMoreAction('variation')"
            >
              <v-icon left>mdi-update</v-icon> Variation Order
            </v-btn>
          </template>
          <span>Create variation order</span>
        </v-tooltip>
      </template>
      <template v-else-if="detail.status == 1">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
              v-on:click="updateMoreAction('edit')"
            >
              <v-icon left>mdi-pencil</v-icon> Edit
            </v-btn>
          </template>
          <span>Edit Quotation</span>
        </v-tooltip>
      </template>
      <template v-if="false">
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
              v-on:click="updateMoreAction('revise')"
            >
              <v-icon left>mdi-update</v-icon> Revise
            </v-btn>
          </template>
          <span>Click here to revise Quotation</span>
        </v-tooltip>
      </template>
      <template v-if="moreActions.length < 3">
        <v-tooltip
          top
          content-class="custom-top-tooltip"
          v-for="(more, index) in moreActions"
          :key="index"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              :loading="formLoading"
              :disabled="more.disabled"
              v-bind="attrs"
              v-on="on"
              v-on:click="updateMoreAction(more.action)"
            >
              <v-icon small>{{ more.icon }}</v-icon> {{ more.title }}
            </v-btn>
          </template>
          <span>Click here to {{ more.title }}</span>
        </v-tooltip>
      </template>
      <v-menu
        v-else
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container v-bar class="detail-quotation-vbar" fluid>
        <v-row dense>
          <v-col cols="5" class="pb-0">
            <v-container v-if="!lodash.isEmpty(prospect)" fluid>
              <table width="100%" style="table-layout: fixed">
                <tr>
                  <td colspan="2" class="py-1 pb-4 font-size-18">
                    <p
                      class="m-0 font-weight-700 font-size-18 pt-3 pb-1 color-custom-blue"
                    >
                      Prospect
                    </p>
                  </td>
                </tr>
                <tr>
                  <td class="py-1 font-size-18">Prospect #</td>
                  <td>
                    <Barcode
                      small
                      route="prospect.detail"
                      :barcode="prospect.barcode"
                      :id="prospect.id"
                    ></Barcode>
                  </td>
                </tr>
                <tr>
                  <td class="py-1 font-size-18">Name</td>
                  <td class="py-1 font-size-18 font-weight-600">
                    <ShowValue
                      :object="prospect"
                      v-if="prospect.first_name"
                      object-key="first_name"
                      label="first name"
                    ></ShowValue>
                    <ShowValue
                      v-if="prospect.last_name"
                      :object="prospect"
                      object-key="last_name"
                      label="last name"
                    ></ShowValue>
                  </td>
                </tr>
                <tr>
                  <td class="py-1 font-size-18">Phone No.</td>
                  <td class="py-1 font-size-18 font-weight-600">
                    <ShowValue
                      :object="prospect"
                      object-key="phone_number"
                      label="phone no."
                    ></ShowValue>
                  </td>
                </tr>
                <tr>
                  <td class="py-1 font-size-18">Source</td>
                  <td class="py-1 font-size-18 font-weight-600">
                    <ShowValue
                      :object="prospect"
                      object-key="source.text"
                      label="source"
                    ></ShowValue>
                  </td>
                </tr>
                <tr>
                  <td class="py-1 font-size-18">Prospect Value</td>
                  <td class="py-1 font-size-18 font-weight-600">
                    {{ formatCompactNumber(prospect.lead_value) }}
                  </td>
                </tr>
                <tr v-if="!prospect.date_converted && prospect.is_accepted">
                  <td colspan="2" class="py-4">
                    <v-btn
                      color="cyan white--text"
                      v-on:click="to_customer()"
                      class="custom-bold-button"
                      ><v-icon small>mdi-reload</v-icon> Convert to Customer
                    </v-btn>
                  </td>
                </tr>
              </table>
            </v-container>
          </v-col>
          <v-col cols="7" class="pb-0 gray-background px-4">
            <v-container fluid>
              <v-row dense>
                <v-col md="12">
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <p
                    v-else
                    class="m-0 font-weight-700 font-size-18 pt-3 pb-1 color-custom-blue"
                    v-on:dblclick="updateField('quotation_title')"
                  >
                    <template v-if="updateDetailDialog.quotation_title">
                      <v-text-field
                        ref="quotation_title"
                        v-model.trim="updateDetail.quotation_title"
                        dense
                        filled
                        color="cyan"
                        :label="`Quotation Title`"
                        :rules="[
                          validateRules.minLength(
                            updateDetail.quotation_title,
                            `Quotation Title`,
                            1
                          ),
                          validateRules.maxLength(
                            updateDetail.quotation_title,
                            `Quotation Title`,
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                        :loading="particuleFieldLoading"
                        :disabled="particuleFieldLoading"
                        @keydown.esc="onEscParticularField"
                        @keydown.enter="updateParticularField"
                      ></v-text-field>
                    </template>
                    <template v-else>
                      <span
                        :class="{
                          'custom-bottom-border': canUpdateQuotation,
                        }"
                        >{{ detail.quotation_title }}</span
                      >
                      <UpdateTooltip v-if="canUpdateQuotation"></UpdateTooltip>
                    </template>
                  </p>
                </v-col>
                <v-col md="12">
                  <template v-if="pageLoading">
                    <v-skeleton-loader
                      v-for="(d, i) in 4"
                      :key="i"
                      class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                      :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <table class="width-100" style="table-layout: fixed">
                      <tr>
                        <td class="font-size-18 pt-3 pb-1" width="40%">
                          Quotation #
                        </td>
                        <td
                          class="font-weight-600 font-size-18 pt-3 pb-1"
                          width="60%"
                        >
                          {{ detail.barcode }}
                        </td>
                      </tr>
                      <tr v-if="quote_package">
                        <td class="font-size-18 py-1" width="40%">Package #</td>
                        <td
                          class="font-weight-600 font-size-18 py-1"
                          width="60%"
                        >
                          <span
                            class="color-custom-blue cursor-pointer"
                            v-on:click="push_to_package()"
                            >{{ quote_package.barcode }}</span
                          >
                        </td>
                      </tr>
                      <tr v-if="project && project.id">
                        <td class="font-size-18 py-1" width="40%">Project #</td>
                        <td
                          class="font-weight-600 font-size-18 py-1"
                          width="60%"
                        >
                          <v-layout>
                            <v-flex
                              ><v-chip
                                small
                                outlined
                                label
                                v-on:click="routeToProject()"
                                color="chip-custom-blue"
                                class="cursor-pointer font-weight-600 font-size-18"
                                >{{ project.barcode }}</v-chip
                              ></v-flex
                            >
                            <v-flex style="max-width: 450px"
                              ><p
                                class="ml-2 font-weight-600 font-size-18 text-truncate mb-0"
                              >
                                {{ project.name }}
                              </p></v-flex
                            >
                          </v-layout>
                        </td>
                      </tr>
                      <tr v-if="false">
                        <td class="font-size-18 pb-1" width="40%">
                          Rate opportunity
                        </td>
                        <td
                          width="60%"
                          class="font-weight-600 font-size-18 pb-1"
                          v-on:dblclick="updateField('rating')"
                        >
                          <template v-if="updateDetailDialog.rating">
                            <v-rating
                              ref="rating"
                              class="remove-y-padding"
                              v-model.trim="updateDetail.rating"
                              background-color="orange lighten-3"
                              color="orange"
                              :loading="particuleFieldLoading"
                              :disabled="particuleFieldLoading"
                              @keydown.esc="onEscParticularField"
                              @input="updateParticularField"
                              @keydown.enter="updateParticularField"
                            ></v-rating>
                          </template>
                          <template v-else>
                            <span class="d-inline-block"
                              ><v-rating
                                readonly
                                class="remove-y-padding"
                                v-model.trim="detail.rating"
                                background-color="orange lighten-3"
                                color="orange"
                              ></v-rating
                            ></span>
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="40%">
                          Reference #
                        </td>
                        <td
                          width="60%"
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                          v-on:dblclick="updateField('reference')"
                        >
                          <template v-if="updateDetailDialog.reference">
                            <v-text-field
                              ref="reference"
                              v-model.trim="updateDetail.reference"
                              dense
                              filled
                              color="cyan"
                              label="Reference #"
                              :rules="[
                                validateRules.minLength(
                                  updateDetail.reference,
                                  'Reference',
                                  1
                                ),
                                validateRules.maxLength(
                                  updateDetail.reference,
                                  'Reference',
                                  100
                                ),
                              ]"
                              solo
                              flat
                              hide-details
                              :loading="particuleFieldLoading"
                              :disabled="particuleFieldLoading"
                              @keydown.esc="onEscParticularField"
                              @keydown.enter="updateParticularField"
                            ></v-text-field>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation,
                              }"
                              >{{ detail.reference }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <tr v-if="false">
                        <td class="font-size-18 py-1" width="40%">
                          Sales representative
                        </td>
                        <td
                          width="60%"
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <template v-if="updateDetailDialog.sales">
                            <v-text-field
                              ref="sales"
                              v-model.trim="updateDetail.sales"
                              dense
                              filled
                              color="cyan"
                              label="Sales representative"
                              :rules="[
                                validateRules.minLength(
                                  updateDetail.sales,
                                  'Sales representative',
                                  1
                                ),
                                validateRules.maxLength(
                                  updateDetail.sales,
                                  'Sales representative',
                                  100
                                ),
                              ]"
                              solo
                              flat
                              hide-details
                              :loading="particuleFieldLoading"
                              :disabled="particuleFieldLoading"
                              @keydown.esc="onEscParticularField"
                              @keydown.enter="updateParticularField"
                            ></v-text-field>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation,
                              }"
                              >{{ detail.sales }}</span
                            >
                            <UpdateTooltip
                              v-if="false && canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="40%">
                          Prepared by
                        </td>
                        <td
                          width="60%"
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          <span >{{ detail.prepared_by }}</span >
                        </td>
                      </tr>
                      <tr v-if="detail.assistant_relation">
                        <td class="font-size-18 py-1" width="40%">
                          CO-Designer
                        </td>
                        <td
                          width="60%"
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          {{ detail.assistant_relation.full_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-18 py-1" width="40%">
                          Quotation Amount
                        </td>
                        <td
                          width="60%"
                          class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
                        >
                          {{ formatMoneyTwoDecimal(detail.total) }}
                        </td>
                      </tr>
                    </table>
                    <table width="100%" style="table-layout: fixed">
                      <tr class="custom-border-top">
                        <td
                          class="font-size-18 py-1 custom-border-right pr-4"
                          width="200"
                        >
                          Created date
                        </td>
                        <td
                          v-if="detail.sent_date"
                          class="font-size-18 px-4 py-1 custom-border-right"
                          width="200"
                        >
                          Sent date
                        </td>
                        <td class="font-size-18 px-4 py-1" width="200">
                          Open till
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="200"
                          class="font-weight-600 font-size-18 py-1 pr-4 custom-border-right"
                          v-on:dblclick="updateField('date')"
                        >
                          <template v-if="updateDetailDialog.date">
                            <v-menu
                              v-model="createdDatePicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              @keydown.esc="onEscParticularField"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  ref="date"
                                  dense
                                  filled
                                  flat
                                  label="Date"
                                  hide-details
                                  solo
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :value="getFormattedDate(updateDetail.date)"
                                  color="cyan"
                                  :loading="particuleFieldLoading"
                                  :disabled="particuleFieldLoading"
                                  @keydown.esc="onEscParticularField"
                                  @keydown.enter="updateParticularField"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model.trim="updateDetail.date"
                                color="cyan"
                                @keydown.esc="onEscParticularField"
                                v-on:input="updateParticularField"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="cyan"
                                  @click="createdDatePicker = false"
                                >
                                  Cancel
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation,
                              }"
                              >{{ formatDate(detail.date) }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                        <td
                          width="200"
                          v-if="detail.sent_date"
                          class="font-weight-600 font-size-18 py-1 px-4 custom-border-right"
                          v-on:dblclick="updateField('sent_date')"
                        >
                          <template v-if="updateDetailDialog.sent_date">
                            <v-menu
                              v-model="sentDatePicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              @keydown.esc="onEscParticularField"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  ref="sent_date"
                                  dense
                                  filled
                                  flat
                                  label="Sent date"
                                  hide-details
                                  solo
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :value="
                                    getFormattedDate(updateDetail.sent_date)
                                  "
                                  color="cyan"
                                  :loading="particuleFieldLoading"
                                  :disabled="particuleFieldLoading"
                                  @keydown.esc="onEscParticularField"
                                  @keydown.enter="updateParticularField"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model.trim="updateDetail.sent_date"
                                color="cyan"
                                @keydown.esc="onEscParticularField"
                                v-on:input="updateParticularField"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="cyan"
                                  @click="sentDatePicker = false"
                                >
                                  Cancel
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation,
                              }"
                              >{{ formatDate(detail.sent_date) }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                        <td
                          width="200"
                          class="font-weight-600 font-size-18 py-1 px-4"
                          v-on:dblclick="updateField('open_till')"
                        >
                          <template v-if="updateDetailDialog.open_till">
                            <v-menu
                              v-model="openTillPicker"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              @keydown.esc="onEscParticularField"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  ref="open_till"
                                  dense
                                  filled
                                  flat
                                  label="Open till"
                                  hide-details
                                  solo
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :value="
                                    getFormattedDate(updateDetail.open_till)
                                  "
                                  color="cyan"
                                  :loading="particuleFieldLoading"
                                  :disabled="particuleFieldLoading"
                                  @keydown.esc="onEscParticularField"
                                  @keydown.enter="updateParticularField"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model.trim="updateDetail.open_till"
                                color="cyan"
                                @keydown.esc="onEscParticularField"
                                :min="detail.date"
                                v-on:input="updateParticularField"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="cyan"
                                  @click="openTillPicker = false"
                                >
                                  Cancel
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </template>
                          <template v-else>
                            <span
                              :class="{
                                'custom-bottom-border': canUpdateQuotation,
                              }"
                              >{{ formatDate(detail.open_till) }}</span
                            >
                            <UpdateTooltip
                              v-if="canUpdateQuotation"
                            ></UpdateTooltip>
                          </template>
                        </td>
                      </tr>
                    </table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" class="mt-8">
            <v-tabs
              active-class="custom-tab-active"
              v-model="quotationTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent tab-sticky"
            >
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#other"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/quotation.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Overview
              </v-tab>
              <v-tab
                :disabled="pageLoading"
                class="font-size-16 font-weight-600 px-8"
                href="#attachment"
              >
                <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="$assetURL('media/custom-svg/attachment.svg')"
                  />
                  <!--end::Svg Icon-->
                </span>
                Documents
              </v-tab>
              <template v-if="getPermission('line-item:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#line-item"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <v-badge
                    v-if="detail.line_items_count"
                    bordered
                    color="orange darken-4"
                    :content="detail.line_items_count"
                  >
                    {{
                      detail.line_items_count > 1 ? "Line Item" : "Line Item"
                    }}
                  </v-badge>
                  <template v-else> Line Item </template>
                </v-tab>
              </template>
              <template v-if="getPermission('history:view')">
                <v-tab
                  :disabled="pageLoading"
                  class="font-size-16 font-weight-600 px-8"
                  href="#history"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
              </template>
            </v-tabs>
            <v-tabs-items v-model="quotationTab">
              <v-tab-item value="other">
                <v-container fluid class="tab-container">
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Description
                      </h3>
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <read-more
                        class="custom-read-more font-weight-600 font-size-16 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="detail.description || '<em>No Description</em>'"
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-card-text>
                  </v-card>
                  <template v-if="false">
                    <br />
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <h3
                          class="font-weight-700 custom-headline color-custom-blue"
                        >
                          Terms &amp; Conditions
                        </h3>
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="custom-read-more font-weight-600 font-size-16 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.term_conditions ||
                            '<em>No Terms &amp; Conditions</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card>
                  </template>
                  <br />
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Internal Notes &amp; Attachments
                      </h3>
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <v-layout>
                        <v-flex md6 class="custom-grey-border mr-4 p-4">
                          <label class="font-size-16 font-weight-600">
                            Internal Note
                            <template v-if="detail.admin_note_relation">
                              -
                              <ShowValue
                                :object="detail"
                                object-key="admin_note_relation.label"
                                label="label"
                              ></ShowValue>
                            </template>
                          </label>
                          <read-more
                            class="custom-read-more custom-border-top pt-2 font-weight-600 font-size-16 pb-1 custom-gray-color"
                            more-str="read more"
                            :text="
                              detail.admin_remark || '<em>No Internal Note</em>'
                            "
                            link="#"
                            less-str="read less"
                            :max-chars="200"
                          >
                          </read-more>
                        </v-flex>
                        <v-flex md6 class="custom-grey-border p-4">
                          <label class="font-size-16 font-weight-600">
                            Client Note
                            <span class="text--secondary font-weight-500"
                              >(visible on PDF)</span
                            >
                            <template v-if="detail.client_note_relation">
                              -
                              <ShowValue
                                :object="detail"
                                object-key="client_note_relation.label"
                                label="label"
                              ></ShowValue>
                            </template>
                          </label>
                          <read-more
                            class="custom-read-more custom-border-top pt-2 font-weight-600 font-size-16 pb-1 custom-gray-color"
                            more-str="read more"
                            :text="
                              detail.client_remark || '<em>No Client Note</em>'
                            "
                            link="#"
                            less-str="read less"
                            :max-chars="200"
                          >
                          </read-more>
                        </v-flex>
                      </v-layout>
                      <br />
                      <v-layout>
                        <v-flex md6 class="custom-grey-border p-4 mr-4">
                          <label class="font-size-16 font-weight-600"
                            >Attachments</label
                          >
                          <div class="custom-border-top pt-2 w-100">
                            <table width="100%" v-if="attachments.length">
                              <tr
                                v-for="(row, index) in attachments"
                                :key="index"
                              >
                                <td height="30px">{{ row.name }}</td>
                                <td height="30px">
                                  <v-icon
                                    v-on:click="download_file(row)"
                                    color="cyan"
                                    >mdi-download</v-icon
                                  >
                                </td>
                                <td height="30px">
                                  <v-icon
                                    v-on:click="delete_file_dialog(row)"
                                    color="red lighten-1"
                                    >mdi-delete</v-icon
                                  >
                                </td>
                              </tr>
                            </table>
                            <em v-else>No Attachments</em>
                          </div>
                        </v-flex>
                        <v-flex md6 class="ml-4 p-4"> </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-tab-item>
              <v-tab-item value="attachment">
                <RelatedDocument
                  pre-quotation
                  v-if="detail.id"
                  :quotationId="detail.id"
                ></RelatedDocument>
              </v-tab-item>
              <template v-if="getPermission('line-item:view')">
                <v-tab-item value="line-item">
                  <v-container>
                    <LineItemPackageDetail
                      v-if="packages.length"
                      v-model="packages"
                      item-title="Package"
                    ></LineItemPackageDetail>
                    <br />
                    <LineItemNewDetailV1
                      v-if="!isPackage && services.length"
                      v-model="services"
                      item-title="Additional Works"
                    ></LineItemNewDetailV1>
                    <br />
                    <v-row>
                      <v-col md="6">
                        <table width="100%" style="table-layout: fixed">
                          <tr>
                            <td height="50px" align="right" width="40%"></td>
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Total Supplier Cost</label
                              >
                            </td>
                            <td height="50px" align="right" width="20%">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="font-size-16 font-weight-600 m-0"
                                    >{{
                                      formatMoneyTwoDecimal(
                                        detail.supplier_cost
                                      )
                                    }}</label
                                  >
                                </template>
                                <span
                                  >Sum of All Line Item Total Supplier
                                  Cost</span
                                >
                              </v-tooltip>
                            </td>
                          </tr>
                          <tr>
                            <td height="50px" align="right" width="40%"></td>
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Total Profit</label
                              >
                            </td>
                            <td height="50px" align="right" width="20%">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="font-size-16 font-weight-600 m-0"
                                    >{{
                                      formatMoneyTwoDecimal(detail.profit)
                                    }}</label
                                  >
                                </template>
                                <span>Sub Total - Total Supplier Cost</span>
                              </v-tooltip>
                            </td>
                          </tr>
                          <tr>
                            <td height="50px" align="right" width="40%"></td>
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Total Quotation Mark Up (in %)</label
                              >
                            </td>
                            <td height="50px" align="right" width="20%">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="font-size-16 font-weight-600 m-0"
                                    >{{ formatNumber( detail.quotation_markup ) }}%</label
                                  >
                                </template>
                                <span
                                  >(Sub Total / Total Supplier Cost) - 1</span
                                >
                              </v-tooltip>
                            </td>
                          </tr>
                          <tr>
                            <td height="50px" align="right" width="40%"></td>
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Total Quotation Profit (in %)</label
                              >
                            </td>
                            <td height="50px" align="right" width="20%">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="font-size-16 font-weight-600 m-0"
                                    >{{ formatNumber( detail.quotation_profit ) }}%</label
                                  >
                                </template>
                                <span>Total Profit / Sub Total</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                      <v-col md="6">
                        <table width="100%">
                          <tr>
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Sub Total</label
                              >
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.sub_total)
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="!isPackage && detail.optional_total">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Optional Total</label
                              >
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.optional_total)
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="false">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Optional (with sub total)</label
                              >
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(
                                  detail.optional_total + detail.sub_total
                                )
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="detail.discount_amount">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Discount
                              </label>
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.discount_amount)
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="detail.discount_two_amount">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Discount
                              </label>
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.discount_two_amount)
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="false && detail.tax_value">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Tax [{{ formatNumber(detail.tax_value) }}%]
                                (without discount)</label
                              >
                            </td>
                            <td height="50px" align="center" width="35%">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="cursor-pointer font-size-16 font-weight-600 m-0"
                                    >{{
                                      formatMoneyTwoDecimal(detail.sub_total)
                                    }}</label
                                  >
                                </template>
                                <span class="text-capitalize"
                                  >Taxable Amount</span
                                >
                              </v-tooltip>
                            </td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(
                                  detail.tax_without_discount
                                )
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="detail.tax_value">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Tax [{{ formatNumber(detail.tax_value) }}%]
                              </label>
                            </td>
                            <td height="50px" align="center" width="35%">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="cursor-pointer font-size-16 font-weight-600 m-0"
                                    >{{
                                      formatMoneyTwoDecimal(
                                        detail.taxable_amount
                                      )
                                    }}</label
                                  >
                                </template>
                                <span class="text-capitalize"
                                  >Taxable Amount</span
                                >
                              </v-tooltip>
                            </td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.tax_amount)
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="detail.adjustment">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Adjustment</label
                              >
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.adjustment)
                              }}</label>
                            </td>
                          </tr>
                          <tr v-if="false">
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Grand Total (without discount)</label
                              >
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(
                                  detail.total_without_discount
                                )
                              }}</label>
                            </td>
                          </tr>
                          <tr>
                            <td height="50px" align="right" width="40%">
                              <label class="font-size-16 font-weight-500 m-0"
                                >Grand Total
                              </label>
                            </td>
                            <td height="50px" align="center" width="35%"></td>
                            <td height="50px" align="right" width="25%">
                              <label class="font-size-16 font-weight-600 m-0">{{
                                formatMoneyTwoDecimal(detail.total)
                              }}</label>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </template>
              <template v-if="getPermission('history:view')">
                <v-tab-item value="history">
                  <InternalHistoryDetail
                    type="quotation"
                    is-package
                    :type_id="detail.id"
                  ></InternalHistoryDetail>
                </v-tab-item>
              </template>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <Dialog :common-dialog="delete_dialog">
        <template v-slot:title> Delete Confirmation </template>
        <template v-slot:body>
          <v-row>
            <v-col md="10" class="py-0" offset-md="1">
              Are you sure, you want to delete file <b>{{ delete_file.name }}</b
              >?
            </v-col>
          </v-row>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="delete_loading"
            :loading="delete_loading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="red lighten-1"
            v-on:click="delete_file_confirm()"
          >
            Yes! Delete
          </v-btn>
          <v-btn
            :disabled="delete_loading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="delete_dialog = false"
          >
            No! Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="accept_dialog" dense>
        <template v-slot:title> Mark As Accepted </template>
        <template v-slot:body>
          <v-form
            ref="accepted_form"
            v-model="accepted_valid"
            lazy-validation
            v-on:submit.stop.prevent="update_accepted_file()"
          >
            <v-row class="mx-2 my-2">
              <v-col md="10" class="py-0" offset-md="1">
                <br />
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <v-icon large>mdi-information-outline</v-icon> Are you sure,
                  you want to mark as accepted?
                </p>
                <br />
                <v-layout>
                  <v-flex md6>
                    <v-checkbox
                      hide-details
                      class="m-0 p-0"
                      v-model="has_attachment"
                      color="cyan"
                      :true-value="1"
                      :false-value="0"
                      label="Has Attachment?"
                      v-on:change="init_attachment()"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex md6> </v-flex>
                </v-layout>
                <br />
                <table width="100%" v-if="has_attachment">
                  <tbody>
                    <tr v-for="(row, index) in quote_attachments" :key="index">
                      <td width="50%" class="px-2 py-2">
                        <v-file-input
                          prepend-inner-icon="mdi-attachment mdi-rotate-90"
                          placeholder="Click here to select file"
                          label="Click here to select file"
                          prepend-icon=""
                          color="cyan"
                          solo
                          flat
                          v-model="row.accepted_file"
                          dense
                          v-on:change="update_file_name(index)"
                          :rules="[
                            validateRules.file_required(
                              row.accepted_file,
                              'File'
                            ),
                          ]"
                          hide-details
                        ></v-file-input>
                      </td>
                      <td width="50%" class="px-2 py-2">
                        <v-text-field
                          placeholder="File Name"
                          label="File Name"
                          dense
                          solo
                          flat
                          color="cyan"
                          v-model="row.file_name"
                          hide-details
                          class="has-delete-outer-icon"
                          append-outer-icon="mdi-delete"
                          v-on:click:append-outer="remove_row(index)"
                          :suffix="get_file_extension(row.file_type)"
                          :rules="[
                            validateRules.required(row.file_name, 'File Name'),
                          ]"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td height="10px" colspan="2"></td>
                    </tr>
                    <tr v-if="quote_attachments.length < 5">
                      <td colspan="2" class="px-2">
                        <v-btn
                          color="cyan white--text"
                          small
                          v-on:click="add_attachment_row()"
                          class="custom-bold-button"
                          >Add More...</v-btn
                        >
                      </td>
                    </tr>
                    <tr>
                      <td height="10px" colspan="2"></td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="update_accepted_file()"
            :disabled="!accepted_valid || accepted_loading"
            :loading="accepted_loading"
          >
            Submit
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="accept_dialog = false"
            :disabled="accepted_loading"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="reject_dialog" dense>
        <template v-slot:title> Mark As Rejected </template>
        <template v-slot:body>
          <v-form
            ref="rejected_form"
            v-model="rejected_valid"
            lazy-validation
            v-on:submit.stop.prevent="update_accepted_file(true)"
          >
            <v-row class="mx-2 my-2">
              <v-col md="10" class="py-0" offset-md="1">
                <br />
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <v-icon large>mdi-information-outline</v-icon> Are you sure,
                  you want to mark as rejected?
                </p>
                <br />
                <v-layout>
                  <v-flex md6>
                    <v-checkbox
                      hide-details
                      class="m-0 p-0"
                      v-model="has_attachment"
                      color="cyan"
                      :true-value="1"
                      :false-value="0"
                      label="Has Attachment?"
                      v-on:change="init_attachment()"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex md6> </v-flex>
                </v-layout>
                <br />
                <table width="100%" v-if="has_attachment">
                  <tbody>
                    <tr v-for="(row, index) in quote_attachments" :key="index">
                      <td width="50%" class="px-2 py-2">
                        <v-file-input
                          prepend-inner-icon="mdi-attachment mdi-rotate-90"
                          placeholder="Click here to select file"
                          label="Click here to select file"
                          prepend-icon=""
                          color="cyan"
                          solo
                          flat
                          v-model="row.accepted_file"
                          dense
                          v-on:change="update_file_name(index)"
                          :rules="[
                            validateRules.file_required(
                              row.accepted_file,
                              'File'
                            ),
                          ]"
                          hide-details
                        ></v-file-input>
                      </td>
                      <td width="50%" class="px-2 py-2">
                        <v-text-field
                          placeholder="File Name"
                          label="File Name"
                          dense
                          solo
                          flat
                          color="cyan"
                          v-model="row.file_name"
                          hide-details
                          class="has-delete-outer-icon"
                          append-outer-icon="mdi-delete"
                          v-on:click:append-outer="remove_row(index)"
                          :suffix="get_file_extension(row.file_type)"
                          :rules="[
                            validateRules.required(row.file_name, 'File Name'),
                          ]"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td height="10px" colspan="2"></td>
                    </tr>
                    <tr v-if="quote_attachments.length < 5">
                      <td colspan="2" class="px-2">
                        <v-btn
                          color="cyan white--text"
                          small
                          v-on:click="add_attachment_row()"
                          class="custom-bold-button"
                          >Add More...</v-btn
                        >
                      </td>
                    </tr>
                    <tr>
                      <td height="10px" colspan="2"></td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="update_accepted_file(true)"
            :disabled="!rejected_valid || rejected_loading"
            :loading="rejected_loading"
          >
            Submit
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="reject_dialog = false"
            :disabled="rejected_loading"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="delDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="pt-0 custom-search-filter">
            <p class="font-weight-600 black--text">Are you sure, you want to delete Quotation # <Barcode small :barcode="delObj.barcode"/>?</p>
            <p class="my-0 red--text font-size-14">Note: <span class="text-italic">Related summary reports will also be deleted.</span></p>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="delLoading"
            :loading="delLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            color="red lighten-1 white--text"
            v-on:click="yesDelete()"
          >
            Yes! Delete
          </v-btn>
          <v-btn
            :disabled="delLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="noCancelDelete()"
          >
            No! Cancel
          </v-btn>
        </template>
      </Dialog>
      <send-mail
        :mail-dialog="mail_dialog"
        :title="`Send Quotation Mail`"
        type="quotation"
        :type-id="quotation"
        v-on:close="mail_dialog = false"
        v-on:success="loadContent()"
      ></send-mail>
    </template>
  </DetailTemplate>
</template>

<script>
import DetailQuotationMixin from "@/core/lib/quotation/detail.pre.quotation.mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import UpdateTooltip from "@/view/pages/partials/UpdateTooltip.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import LineItemPackageDetail from "@/view/pages/partials/Line-Item-Package-Detail.vue";
import LineItemNewDetailV1 from "@/view/pages/partials/Line-Item-New-DetailV1.vue";
import { PATCH, DELETE } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import SendMail from "@/view/pages/partials/Send-Mail.vue";
import ShowValue from "@/view/pages/partials/Show-Value.vue";
import RelatedDocument from "@/view/pages/partials/Related-Document.vue";
import JwtService from "@/core/services/jwt.service";

// import Base64 from "file-base64";

export default {
  mixins: [DetailQuotationMixin],
  components: {
    Dialog,
    Barcode,
    UpdateTooltip,
    DetailTemplate,
    CustomStatus,
    SendMail,
    ShowValue,
    RelatedDocument,
    InternalHistoryDetail,
    LineItemPackageDetail,
    LineItemNewDetailV1,
  },
  props: {
    isQuotation: {
      type: Boolean,
      default: true,
    },
    isPackage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentSortClass: ".tab-container",
      quote_files: [],
      related_files: [],
      file_checkbox: [],
      file_selected: [],
      pdf_view: {},
      currentUser: {},
      merge_pdf_name: null,
      pdf_view_src: null,
      pdf_view_name: null,
      pdf_view_count: 1,
      pdf_view_page: 1,
      pdf_signed_copy_name: null,
      pdf_signed_copy_file: null,
      pdf_signed_copy_type: null,
      pdf_signed_copy_loading: false,
      merge_pdf_loading: false,
      merge_dialog: false,
      mail_dialog: false,
      pdf_view_dialog: false,
      merge_pdf_valid: true,
      upload_signed_valid: true,
      pdf_view_signed_dialog: false,
      pdf_view_loaded: false,
      upload_valid: true,
      delete_loading: false,
      delete_dialog: false,
      delete_file: {},
      merge_preview: false,
      action_dialog: false,
      action_id: null,
      action_type: null,
      action_barcode: null,
      action_loading: false,
      generate_case_trust: 0,
      case_trust_dialog: false,
      case_trust_loading: false,
      accepted_valid: true,
      has_attachment: 0,
      accepted_loading: false,
      accept_dialog: false,
      upload_dialog: false,
      upload_loading: false,
      merge_form_valid: true,
      project: {},
      case_trust: {},
      attachments: [],
      quote_attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      upload_documents: [
        {
          upload_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      reject_dialog: false,
      rejected_valid: true,
      rejected_loading: false,
      delDialog: false,
      delId: 0,
      delObj: { barcode: null },
      delLoading: false,
    };
  },
  methods: {
    yesDelete() {
      if(this.currentUser.super_admin == 0){
        return false;
      }
      this.delLoading = true;
      this.$store.dispatch(DELETE, { url: `pre-quotation/${this.delId}` })
        .then(() => {
          this.noCancelDelete();
          this.goBack();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.delLoading = false;
        });
    },
    noCancelDelete() {
      this.delDialog = false;
      this.delId = 0;
      this.delObj = { barcode: null };
    },
    openDeleteDialog() {
      if(this.currentUser.super_admin == 0){
        return false;
      }
      this.delId = this.detail.id;
      this.delObj = { barcode: this.detail.barcode };
      this.delDialog = true;
    },
    to_customer() {
      if (this.prospect && this.prospect.id) {
        const route = this.getDefaultRoute("customer.create", {
          query: { prospect: this.prospect.id },
        });
        this.$router.push(route);
      }
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    init_attachment() {
      this.quote_attachments = [];
      this.$nextTick(() => {
        this.add_attachment_row();
      });
    },
    update_file_name(index) {
      const file = this.quote_attachments[index].accepted_file;
      if (file) {
        this.quote_attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.quote_attachments[index].file_type = file.name.split(".").pop();
      }
    },
    update_upload_name(index) {
      const file = this.upload_documents[index].upload_file;
      if (file) {
        this.upload_documents[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.upload_documents[index].file_type = file.name.split(".").pop();
      }
    },
    async update_accepted_file(rejected) {
      let a_key = "accepted";
      if (rejected) {
        a_key = "rejected";
      }

      const validateStatus = this.$refs[`${a_key}_form`].validate();

      const formErrors = this.validateForm(this.$refs[`${a_key}_form`]);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      if (this.has_attachment) {
        const accepted_file = this.lodash.filter(
          this.lodash.map(this.quote_attachments, `accepted_file`)
        );
        if (accepted_file.length < 1) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please select atleast one file.")
          );
          return false;
        }
      }

      const result = [];

      for await (const row of this.quote_attachments) {
        if (row.file_name) {
          result.push({
            accepted_file: await this.convert_base_64(row.accepted_file),
            file_name: row.file_name,
            file_type: row.file_type,
          });
        }
      }

      this[`${a_key}_loading`] = true;

      this.$store
        .dispatch(PATCH, {
          url: `pre-quotation/${this.quotation}/mark-as-${a_key}`,
          data: { file: result, has_attachment: this.has_attachment },
        })
        .then(() => {
          this.loadContent();
          this.accept_dialog = false;
          this.reject_dialog = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this[`${a_key}_loading`] = false;
        });
    },
    remove_row(index) {
      this.quote_attachments.splice(index, 1);
      this.$nextTick(() => {
        if (!this.quote_attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      this.quote_attachments.push({
        accepted_file: null,
        file_name: null,
      });
    },
    remove_upload_row(index) {
      this.upload_documents.splice(index, 1);
      this.$nextTick(() => {
        if (!this.upload_documents.length) {
          this.add_upload_row();
        }
      });
    },
    add_upload_row() {
      this.upload_documents.push({
        accepted_file: null,
        file_name: null,
      });
    },
    delete_file_dialog(row) {
      this.delete_dialog = true;
      this.delete_file = row;
    },
    download_file(row) {
      if (row.url) {
        window.open(row.url, "_blank");
      }
    },
    delete_file_confirm() {
      this.delete_loading = true;
      this.$store
        .dispatch(DELETE, {
          url: "file-manager/delete/" + this.delete_file.id,
        })
        .then(() => {
          this.delete_dialog = false;
          const index = this.lodash.findIndex(this.attachments, {
            id: this.delete_file.id,
          });
          if (index >= 0) {
            this.attachments.splice(index, 1);
          }
          this.$nextTick(() => {
            this.delete_file = {};
          });
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },
  },
  mounted() {
    this.currentUser = JwtService.currentUser();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
